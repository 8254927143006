<!--
 * @Author: your name
 * @Date: 2021-11-09 16:15:38
 * @LastEditTime: 2021-12-07 11:11:00
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\views\pages\general-user\visualization\video-control\index.vue
-->
<template>
  <div>
    <el-form ref="queryFormRef" :inline="true" :model="queryForm" label-width="90px">
      <el-form-item prop="categoryId" label="所属机构">
        <el-select v-model="queryForm.orgId" placeholder="请选择">
          <el-option v-for="item in orgList" :key="item.orgId" :label="item.orgName" :value="item.orgId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="摄像头名称">
        <el-input v-model="queryForm.deviceName" placeholder="请输入摄像头名称" style="width:280px"></el-input>
      </el-form-item>
      <el-form-item label="摄像头编号">
        <el-input v-model="queryForm.deviceModel" placeholder="输入摄像头编号" style="width:280px"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button @click="handleReset">重置</el-button>
      </el-form-item>
    </el-form>
    <el-button
      type="primary"
      icon="el-icon-plus"
      style="margin-bottom: 16px"
      v-if="$check('env-camera-management-add')"
      @click="handleAdd"
      >新增摄像头</el-button
    >
    <el-table v-loading="tableLoading" :data="controlList" row-key="id">
      <el-table-column fixed align="center" width="80" type="index" label="序号"></el-table-column>
      <el-table-column
        fixed
        align="center"
        width="300"
        prop="orgName"
        label="所属机构"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        fixed
        align="center"
        prop="deviceName"
        label="摄像头名称"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        fixed
        align="center"
        width="300"
        prop="deviceModel"
        label="设备型号"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column fixed="right" align="center" width="200" label="操作">
        <template slot-scope="{ row }">
          <div>
            <span v-if="$check('env-camera-management-edit')" class="action-btn" @click="handleGrant(row.id)">查看详情</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="total"
      :current-page="queryForm.pageNo"
      :page-size="queryForm.pageSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 30, 50]"
      layout="total, sizes, prev, pager, next"
    ></el-pagination>
    <!-- 新增编辑 -->
    <contro-dialog ref="controRef" :headerText.sync="headerText" @on-success="onSuccess"></contro-dialog>
  </div>
</template>
<script>
import controDialog from './controDialog.vue';
import { getList, getOrgList, postDetail } from '@/api/general-user/video-control.js';

export default {
  components: {
    controDialog
  },
  data() {
    return {
      total: 0,
      queryForm: {
        pageNo: 1,
        pageSize: 10,
        deviceName: '',
        orgId: '',
        deviceModel: ''
      },
      orgList: [],
      tableLoading: false,
      controlList: [],
      headerText: '新增摄像头'
    };
  },
  created() {
    this.getList();
    this.getOrgList();
  },
  methods: {
    getOrgList() {
      getOrgList().then(res => {
        if (res.code !== 200) {
          return;
        }
        this.orgList = res.data;
      });
    },
    handleSearch() {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = 10;
      this.getList();
    },
    handleSizeChange(size) {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = size;
      this.getList();
    },
    handleCurrentChange(current) {
      this.queryForm.pageNo = current;
      this.getList();
    },
    getList() {
      this.tableLoading = true;
      getList(this.queryForm)
        .then(res => {
          this.total = res.data.totalRows;
          this.controlList = res.data.rows;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    handleReset() {
      this.$refs.queryFormRef.resetFields();
      this.queryForm = this.$options.data().queryForm;
      this.handleSearch();
    },
    handleAdd() {
      this.headerText = '新增摄像头';
      this.$refs.controRef.open();
    },
    onSuccess(id) {
      if (!id) {
        this.handleReset();
      } else {
        this.getList();
      }
    },
    handleGrant(id) {
      postDetail({ id }).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.message);
          return;
        }
        this.headerText = '查看详情';
        this.$refs.controRef.open(res.data);
      });
    }
  }
};
</script>
